body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logout_sidebar_button {
  position: absolute;
  display: inline-block;
  bottom: 0;
  left: 15px;
}

.react-responsive-modal-modal {
  border-radius: 20px;
  padding: 2.5em !important;
  /* max-width: 950px !important; */
  min-width: 500px !important;
}

input:disabled {
  cursor: default;
  background: #E5E5EA;
}

.PhoneInput:focus {
  border: #FABF01 !important;
}

.custom-input-label {
  @apply absolute top-0 left-0 px-4 py-4 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out
}

.custom-input {
  @apply border border-gray-200 focus:outline-none rounded-md focus:border-primary focus:shadow-sm w-full mt-2 pb-1 p-3 h-14 text-[12px]
}

.custom-phone-input{
  @apply border border-gray-200 focus:outline-none rounded-md focus:border-primary focus:shadow-sm w-full mt-2 pb-1 p-3 h-14 text-[12px]

}
.custom-phone-input[disabled] {
  @apply bg-gray-100
}
.PhoneInputInput {
  border: none;
  outline: none;
}

.PhoneInputInput {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; 
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #2A2A2A;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #838383;
  border-radius: 10px;
  border: 3px solid #2A2A2A; 
}


.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #838383 #151515;
  
}


.custom-scrollbar {
  padding-right: 16px; 
  margin-right: -16px; 
  box-sizing: content-box; 
}